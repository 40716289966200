.site-header {
    border-bottom: 1px solid $grey-color-light;
    background-color: #fff;
    opacity: 0.95;
    position:fixed;
    left:0px;
    top:0px;
    width:100%;
    z-index: 50;
}

.site-title {
    font-size: 20px;
    line-height: $nav-height;
    letter-spacing: -1px;
    margin-bottom: 0;
}
.site-nav {
    float: right;
    line-height: $nav-height;
    
    .nav-trigger {
        display: none;
    }

    .menu-icon {
        display: none;
    }
    
    .page-link{
        line-height: $line-height;
        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 10px;
        }
    }
    // added for hamburger
    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: $horizontal-spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        label[for="nav-trigger"] {
            display: block;
            float: right;
            width: 36px;
            height: 36px;
            z-index: 2;
            cursor: pointer;
        }

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg path {
                fill: $grey-color-dark;
            }
        }

        input ~ .trigger {
            clear: both;
            display: none;
        }

        input:checked ~ .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 10px;
        }
    }
}

.header-bar{
    border-bottom: 1px solid $light-gray;
    font-size: 20px;
    display: block;
    opacity: 0.75;
    width: 100%;
    text-align: center;
    padding-top: 25px;
    padding-bottom: $space-4;
    line-height: 3em;
    z-index: 25;
    h1{
        color: $theme-color;
        font-size:75px;
    }
    h2{
        font-size:25px;
    }
}
