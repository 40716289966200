.profile {
    float: right !important;
    margin-left: 10px;
    img {
        box-shadow: 0 0 5px $grey-color;
        width: 100%;
    }
}
.address {
    font-size: 12px;
    font-family: monospace, monospace;

    p { margin: 0; }
}

.news {
    border-top: 1px solid $grey-color-light;
    margin-top: 30px;
    padding-top: 20px;

    h2 {
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 10px;
    }
    table {
        border-collapse: collapse;
        width: 100%;

        .date { width: 19%; }
        .announcement { width: 81%; }

        tr { text-align: left; }
        td {
            padding: 6px 0;
            vertical-align: top;
            border: none;
        }
    }
}

.social {
    border-top: 1px solid $grey-color-light;
    margin-top: 50px;
    padding-top: 20px;
}
