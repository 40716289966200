.page-content {
    padding: 100px 0; /* VERTICAL PADDING FOR TITLE ON EVERY PAGE */
    text-align: justify;
}
.page-heading {
    font-size: 20px;
}
.contacticon {
    display: block;
    font-size: 60px;
    margin: 10px;
}
.center{
    text-align: center;
}
